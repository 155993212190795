import React, { lazy, Suspense } from 'react';
import reportWebVitals from './reportWebVitals';
import { hydrate, render } from "react-dom";

import Rooms from "./data/rooms";

import Error from "./routes/error";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Room from './components/studio-page';

const Root = lazy(() => import("./routes/root"))
const HomeRoute = lazy(() => import("./routes/home"))
const AboutRoute = lazy(() => import("./routes/about"))
const GearRoute = lazy(() => import("./routes/gear"))
const StudiosRoute = lazy(() => import("./routes/studios"))
const BookingRoute = lazy(() => import("./routes/booking"))

const rootElement = document.getElementById("root");
  
const r = createBrowserRouter([
  {
    path: "/",
    element: <HomeRoute />
  },
  {
    path: "/about",
    element: <Root />,
    children: [
      {
        path: "",
        element: <AboutRoute />
      }
    ]
  },
  {
    path: "/gear",
    element: <Root />,
    children: [
      {
        path: "",
        element: <GearRoute />
      }
    ]
  },
  {
    path: "/booking",
    element: <Root />,
    children: [
      {
        path: "",
        element: <BookingRoute />
      }
    ]
  },
  {
    path: "/studios",
    element: <Root />,
    children: [
      {
        path: "",
        element: <StudiosRoute />
      }
    ]
  },
  ...Rooms.map((room) => {
    return {
      path: "/" + room.url,
      element: <Root />,
      children: [
        {
          path: "",
          element: <Room
            label={room.label}
            img={room.img}
            sub={room.sub}
            copy={room.copy}
            gear={room.gear}
          />
        }
      ]

    }
  }),
  {
    path: "*",
    element: <Root />,
    children: [
      {
        path: "*",
        element: <Error />
      }
    ]
  }
]);

if (rootElement.hasChildNodes()) {
  hydrate(<Suspense fallback={<div className="fb"></div>}>
       <RouterProvider router={r}></RouterProvider>
    </Suspense>, rootElement);
} else {
  render(<Suspense fallback={<div  className="fb"></div>}>
      <RouterProvider router={r}></RouterProvider>
    </Suspense>, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
