import Slider from "./slider"
import "../assets/studios/studios.scss"
export default function Room(props) {
    return <div>
        <div className="pentagon">
        <Slider
                num_items={1} 
                breakpoint={768}
                height={"100%"}
                items={props.img}
                  type={2}
                  />

            <div className="container">
                  <div className="row">
              <img className="w-auto" src={props.label}></img>
            </div>
            </div>
        </div>
        <div className="container studio-container">
              <div className="row">
                <div className="col-md-9">
                    <p>
                        <strong>{props.sub}</strong>
                    </p> 
                    <p>
                        {props.copy}
                        </p>
            </div>
                <h2>
                EQUIPMENT LIST
                </h2>
                <ul className="gear-list">
                      {props.gear && props.gear.map((g) => 
                        <li>
                            {g}
                        </li>
                      )}
                </ul>
              </div>
        </div>
    </div>
}
