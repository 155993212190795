
import img1 from '../assets/home/slider-img/1.JPEG';
import img2 from '../assets/home/slider-img/2.JPEG';
import img3 from '../assets/home/slider-img/3.JPEG';
import img4 from '../assets/home/slider-img/4.JPEG';
import img5 from '../assets/home/slider-img/5.JPEG';

import mixA from '../assets/studios/MIX-A.svg';
import mixB from '../assets/studios/MIX-B.svg';
import mixC from '../assets/studios/MIX-C.svg';
import mixD from '../assets/studios/MIX-D.svg';
import masteringSuite from '../assets/studios/MASTERING-SUITE.svg';
import rehearsalA from '../assets/studios/REHEARSAL-A.svg';
import rehearsalB from '../assets/studios/REHEARSAL-B.svg';
import lounge from '../assets/studios/LOUNGE.svg';

const img = [
    {
      src: img1
    },
    {
      src: img2
    },
    {
      src: img3
    },
    {
      src: img4
    },
    {
      src: img5
    }]

export default [
    {
        url: "mix-a",
        img: [],
        label: mixA,
        sub: "Coming Soon",
        copy: "Dubbed “The Great Room,” Mix A is centered around an SSL 9096J console with tie-lines into the main tracking space.",
        gear: []
    },
    {
        url: "mix-b",
        img: [],
        label: mixB,
        sub: "Coming Soon",
        copy: "Intended to be the studio's centerpiece for music creation and live performance, Mix B boasts a Neve 8088 console, a spacious live room and iso booths.",
        gear: []
    },
    {
        url: "mix-c",
        img: img,
        label: mixC,
        sub: "Mix C shines with its 240 sq. ft. main tracking space and three uniquely designed isolation booths for recording vocals, piano, drums, electric guitars, and acoustic instruments.",
        copy: "The control room offers a rich compliment of audio and video gear, featuring a vintage Neve Kelso console equipped with beautifully crafted microphone preamps, along with an extensive list of world-class outboard microphone preamps, equalizers, compressors, effects, all completed by a top-notch video broadcast studio.",
        gear: [
            "Yamaha Pro R3 Digital Reverb",
            "ADi Mecor Reverb",
            "EMT Dual plate reverb remote control"
        ]
    },
    {
        url: "mix-d",
        img: [],
        label: mixD,
        sub: "Mix D is a private audio and video editing room with tie-lines to world class vocal and instrument isolation booths.",
        copy: "",
        gear: []
    },
    {
        url: "rehearsal-a",
        img: [],
        label: rehearsalA,
        sub: "(Coming soon)",
        copy: "",
        gear: []
    },
    {
        url: "rehearsal-b",
        img: [],
        label: rehearsalB,
        sub: "(Coming soon)",
        copy: "",
        gear: []
    },
    {
        url: "lounge",
        img: [],
        label: lounge,
        sub: "The Lounge is complete with a full kitchen, restrooms, games & musical instruments all set in a comfortable space intended to provide downtime between sessions and/or collaboration with other artists working throughout the facility.",
        copy: "",
        gear: []
    },
    {
        url: "mastering",
        img: [],
        label: masteringSuite,
        sub: "(Coming soon)",
        copy: "Have you mastered audio? If so, then this space is for you.",
        gear: []
    }
]