import "../assets/components/slider.scss";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

export default function Slider(props) {
    const items = props.items;
    const height = props.height
    const breakpoint = props.breakpoint

    const wrapperRef = useRef(null)
    const sliderRef = useRef(null)

    const [deltaDir, setDeltaDir] = useState(0);
    const [indexPos, setIndexPos] = useState(0);

    const [lastPosX, setLastPosX] = useState(0);

    const [iconPrev, setIconPrev] = useState(false)
    const [iconNext, setIconNext] = useState(true)

    const [num_items, setNumItems] = useState(props.num_items);

    const [elementWidth, setElementWidth] = useState(wrapperRef.current ? wrapperRef.current.getBoundingClientRect().width / num_items : 0)
    
    const [multiplier, setMultipler] = useState(1)
    useLayoutEffect(() => {
        window.addEventListener("resize", onResize)
        return () => {  
            window.removeEventListener("resize", onResize)
        }
    })
    useLayoutEffect(() => {
        onResize()
    }, [wrapperRef.current])
    
    useLayoutEffect(() => {
        update({
            currentTarget: wrapperRef.current
        })
    }, [elementWidth])

    const onResize = useCallback(() => {
        window.innerWidth <= breakpoint ? setNumItems(1) : setNumItems(props.num_items)
        
        let w = wrapperRef.current ? wrapperRef.current.getBoundingClientRect().width / num_items : 0
        let adjPos = indexPos * w

        wrapperRef.current.scrollLeft = adjPos
        setElementWidth(w)
    })

    const update = (e) => {
        let wrapper = e.currentTarget
            
        let scrollPos =  wrapper.scrollLeft;
        
        if(scrollPos % elementWidth === 0) return;

        
        let snapTo = deltaDir > 0 ? Math.ceil(scrollPos / elementWidth) * elementWidth 
                                    : Math.floor(scrollPos / elementWidth) * elementWidth;
        
                                    
        if(Math.abs(lastPosX-wrapper.scrollLeft)> elementWidth) {
            snapTo = Math.round(scrollPos / elementWidth) * elementWidth
        }

        setLastPosX(wrapper.scrollLeft)

        wrapper.scrollTo({
            left: snapTo,
            behavior: 'smooth'
        });

        setIndexPos(snapTo / elementWidth)
    }
    
    const onWheel = (e) => {

        if(e.deltaX != 0)
            setDeltaDir(Math.sign(e.deltaX))

        let wrapper = e.currentTarget

        wrapper.scrollLeft += e.deltaX;
        
        update(e)
    }   


    const onScroll = (e) => {
        const wrapper = e.currentTarget

        let snapTo = deltaDir > 0 ? Math.ceil(wrapper.scrollLeft / elementWidth) * elementWidth 
        : Math.floor(wrapper.scrollLeft / elementWidth) * elementWidth;

        
        if(Math.abs(lastPosX-wrapper.scrollLeft)> elementWidth) {
            snapTo = Math.round(wrapper.scrollLeft / elementWidth) * elementWidth
        }

        if(snapTo >= elementWidth) {
            setIconPrev(true)
        } else {
            setIconPrev(false)
        }

        if(Math.round(snapTo / elementWidth) != items.length-num_items) {
            setIconNext(true)
        } else {
            setIconNext(false)
        }
    }

    const nextButton = () => {
        if(!sliderRef.current) return
        
        let deltaQ = elementWidth*num_items
        let deltaPos = deltaQ 

        setDeltaDir(1)

        let newPos = Math.round((sliderRef.current.scrollLeft+deltaPos) / elementWidth) * elementWidth
        sliderRef.current.scrollTo({
            left: newPos,
            behavior: 'smooth'
        });
    }
    const prevButton = () => {
        if(!sliderRef.current) return
        
        let deltaQ = elementWidth*num_items
        let deltaPos = deltaQ
        setDeltaDir(-1)

        let newPos = Math.round((sliderRef.current.scrollLeft-deltaPos) / elementWidth) * elementWidth
        sliderRef.current.scrollTo({
            left: newPos,
            behavior: 'smooth'
        });
    }

    let [dragging, setDragging] = useState(false)
    let [lastMouseState, setLastMouseState] = useState(0)
    const onMouseDown = (e) => {
        setDragging(true)
        setLastMouseState(e.clientX)
    }
    const onMouseMove = (e) => {
        if(!dragging || !wrapperRef.current) return;

        e.currentTarget.scrollLeft += lastMouseState - e.clientX
        setDeltaDir(Math.sign(lastMouseState - e.clientX))
        setLastMouseState(e.clientX)
    }
    const onMouseUp = (e) => {
        setDragging(false)
        update(e)
    }
    return <div ref={wrapperRef} 
                className={"am-slider" + (props.type==2 ? " slider-full" : "")}> 
                {props.type !== 2 && 
                (
                <><div onClick={prevButton} 
                        class={"am-slider-prev" + (iconPrev ? " active" : "")}>
                    le
                </div>
                <div onClick={nextButton} 
                        class={"am-slider-next" + (iconNext ? " active" : "")}>
                    rt
                </div></>
                )}
                
                <div class="am-slider-inner"
                  onScroll={onScroll} 
                  onWheel={onWheel}
                  onMouseDown={onMouseDown}
                  onMouseUp={onMouseUp}
                  onMouseMove={onMouseMove}
                  onTouchStart={onMouseDown}
                  onTouchEnd={onMouseUp}
                  onMouseLeave={onMouseUp}
                  ref={sliderRef}
                  >
                    {items.map((item) =>
                        <a key={item.src} href={item.url} class="am-slider-snap-to" style={{backgroundImage: "url("+item.src+")", width: elementWidth, height: height ? height : elementWidth}}>
                        </a>
                    )}
                </div>


                {props.type == 2 && 
                (
                <>
                    <div className="container">
                        <div className="row">
                            <div className="am-slider-nav">
                            {items.map((item, v) => {
                            return <div 
                                key={v}
                                className={"am-slider-bar" + (v == indexPos ? " active" : "")}
                                onClick={() => {
                                    setIndexPos(v)
                                    sliderRef.current.scrollTo({
                                        left: v * elementWidth,
                                        behavior: 'smooth'
                                    });
                                }}
                                ></div>
                        })}
                        </div>
                        </div>
                    </div>
                </>
                )}

    </div>  
}     